<template>
  <div class="content-wrapper">
    <div class="setting-list-item">
      <span class="title">用户名</span>
      <div class="content-box">
        <template v-if="infoForm.infoType === 'username'">
          <el-input class="input"
                    v-model="infoForm.username" placeholder="请设置用户名" maxlength="10"></el-input>
          <el-button type="text" icon="el-icon-yes" @click="handleInfoUpdate('username')">保存</el-button>
          <el-button type="text" icon="el-icon-yes" @click="handleCancelUpdate('username')">取消</el-button>
        </template>
        <template v-else>
          <div class="content-detail">{{userInfo.username}}</div>
          <el-button type="text" @click="editInfo('username')">修改</el-button>
        </template>
      </div>
    </div>
    <div class="setting-list-item">
      <span class="title">姓名</span>
      <div class="content-box">
        <template v-if="infoForm.infoType === 'name'">
          <el-input class="input"
                    v-model="infoForm.name" placeholder="请输入姓名"></el-input>
          <el-button type="text" icon="el-icon-yes" @click="handleInfoUpdate('name')">保存</el-button>
          <el-button type="text" icon="el-icon-yes" @click="handleCancelUpdate('name')">取消</el-button>
        </template>
        <template v-else>
          <div class="content-detail">{{userInfo.name}}</div>
          <el-button type="text" @click="editInfo('name')">修改</el-button>
        </template>
      </div>
    </div>
    <div class="setting-list-item">
      <span class="title" style="align-self: flex-start">地址</span>
      <div class="content-box">
        <template v-if="infoForm.infoType === 'address'">
          <el-input class="input"
                    v-model="infoForm.address" type="textarea"
                    placeholder="请输入地址" rows="6" resize="none"></el-input>
          <el-button type="text" icon="el-icon-yes" @click="handleInfoUpdate('address')">保存</el-button>
          <el-button type="text" icon="el-icon-yes" @click="handleCancelUpdate('address')">取消</el-button>
        </template>
        <template v-else>
          <div class="content-detail detail-address">{{userInfo.address}}</div>
          <el-button type="text" @click="editInfo('address')">修改</el-button>
        </template>
      </div>
    </div>
    <div class="setting-list-item">
      <span class="title">手机号</span>
      <div class="content-box">
        <div class="content-detail">{{userInfo.phone | formatPhoneNum}}</div>
        <!--<el-button type="text">修改</el-button>-->
      </div>
    </div>
    <div class="setting-list-item">
      <span class="title">密码</span>
      <div class="content-box" v-if="userInfo.hasPwd">
        <div class="content-detail" style="background-color: transparent;"></div>
        <el-button type="text" @click="resetPwdDialog = true">重置</el-button>
      </div>
      <div class="content-box" v-else>
        <div class="item-content" style="color: #a2a2a2;">您还未设置密码</div>
        <el-button type="text" @click="openSetPwdDialog">设置密码</el-button>
      </div>
    </div>

    <el-dialog :visible.sync="setPwdDialog" title="设置登录密码">
      <el-form ref="setPwdForm" :model="pwdForm" :rules="rules" label-width="100px">
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="pwdForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passConfirm">
          <el-input type="password" v-model="pwdForm.passConfirm" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label-width="0" align="center">
          <el-button type="primary" @click="submitSetPwd">确认</el-button>
          <el-button @click="setPwdDialog = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="resetPwdDialog" title="重置登录密码" @close="handleDialogClose('reset')">
      <el-form ref="resetPwdForm" :model="pwdForm" :rules="rules" label-width="100px">
        <!--<el-form-item label="手机号" prop="phone">-->
        <!--  <el-input v-model="pwdForm.phone" placeholder="请输入绑定的手机号" autocomplete="off"></el-input>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="验证码" prop="code">-->
        <!--  <sms-input v-model="pwdForm.code" :code-type="2" code-form="pwdForm"></sms-input>-->
        <!--</el-form-item>-->
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input type="password" v-model="pwdForm.oldPassword" placeholder="请输入原密码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="pwdForm.password" placeholder="请输入密码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passConfirm">
          <el-input type="password" v-model="pwdForm.passConfirm" placeholder="请再次输入密码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label-width="0" align="center">
          <el-button type="primary" @click="submitResetPwd">确认</el-button>
          <el-button @click="resetPwdDialog = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getUserDetail, resetUserPwd, updateUserInfo} from "@/page-home/api/home";
  import SmsInput from "@/page-home/components/SmsInput/index";
  import {removeAllToken} from "@/page-home/utils/storage";

  export default {
    name: "BasicInfo",
    components: {SmsInput},
    data() {
      const validateConfirmPass = (rule, value, callback) => {
        if (value !== this.pwdForm.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        setPwdDialog: false,
        pwdForm: {},
        rules: {
          phone: [
            {required: true, message: '请输入手机号', trigger: 'blur'},
          ],
          code: [
            {required: true, message: '请输入验证码', trigger: 'blur'},
          ],
          oldPassword: [
            {required: true, message: '请输入原密码', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '请输入新密码', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur'}
          ],
          passConfirm: [
            {required: true, message: '请再次输入密码', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur'},
            {validator: validateConfirmPass, trigger: 'blur'}
          ]
        },

        infoForm: {infoType: ''},
        resetPwdDialog: false
      }
    },

    computed: {
      ...mapState({
        userInfo: state => state.userInfo
      })
    },

    filters: {
      formatPhoneNum(value) {
        if (value) {
          return value.substring(0,3) + '****' + value.substring(value.length-4)
        } else {
          return ''
        }
      }
    },

    methods: {
      openSetPwdDialog() {
        this.setPwdDialog = true
        this.$store.commit('SET_USER_INFO_ITEM', {itemName: 'hasPwd', itemValue: true})
      },

      submitSetPwd() {
        this.$refs['setPwdForm'].validate(valid => {
          if (valid) {
            const payload = {
              infoType: 'password',
              password: this.pwdForm.pass
            }
            updateUserInfo(payload).then(res => {
              this.$message.success('密码设置成功，之后可用密码登录')
              this.setPwdDialog = false
              setTimeout(() => {
                this.pwdForm = {}
              }, 300)
            })
          } else {
            return
          }
        })
      },

      submitResetPwd() {
        this.$refs['resetPwdForm'].validate(valid => {
          if (valid) {
            resetUserPwd(this.pwdForm).then(res => {
              this.$message.success('密码重置成功，请重新登录')
              this.$store.commit('SET_USER_INFO', {})
              removeAllToken()
              this.$router.replace('/')
              this.resetPwdDialog = false
            }).catch(err => {
              this.$message.error(err.msg)
            })
          } else {
            return
          }
        })
      },

      handleDialogClose(type) {
        if (type === 'reset') {
          this.pwdForm = {}
          this.$refs['resetPwdForm'].clearValidate()
        }
      },

      editInfo(type) {
        this.infoForm.infoType = type
        this.$set(this.infoForm, type, this.userInfo[type])
        // this.infoForm[type] = this.userInfo[type]
      },
      handleInfoUpdate(type) {
        const payload = {
          infoType: type,
          [type]: this.infoForm[type]
        }
        updateUserInfo(payload).then(res => {
          this.$message.success('信息更新成功')
          getUserDetail().then(res => {
            this.infoForm.infoType = ''
            this.$store.commit('SET_USER_INFO', res.data)
          })
        }).catch(err => {
          if (err.code === 3234) {
            this.$message.error(err.msg)
          } else {
            this.$message.error('信息更新失败')
          }
        })
      },

      handleCancelUpdate() {
        this.infoForm.infoType = ''
      }
    },

    created() {

    }
  }
</script>

<style lang="scss" scoped>
  .content-wrapper {
    height: 100%;
    padding: 16px;
    background-color: #ffffff;
  }
  .setting-list-item {
    display: flex;
    align-items: center;
    margin-left: 50px;
    padding: 16px 0;
    border-bottom: 1px solid #f1f1f1;
    .title {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      width: 140px;
    }
    .content-box {
      display: flex;
      align-items: center;
      .content-detail {
        width: 240px;
        height: 32px;
        margin-right: 16px;
        padding: 0 16px;
        line-height: 32px;
        background: #F9F9F9;
        border-radius: 5px;
      }
      .detail-address {
        height: 100px;
        line-height: 1.5;
      }
      .input {
        width: 240px;
        margin-right: 12px;
      }
    }
  }
</style>
